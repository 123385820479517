<div class="page-title-container bg-white py-2" [attr.id]="pageTitleConfig?.id" *ngIf="pageTitleConfig?.showPageTitleBar">
  <div class="row" [ngClass]="pageTitleConfig?.pageTitleRowClass">
    <!-- Row: 1, Column: 1 => Title Bar Section -->
    <div class="page-title-name-wrapper" [ngClass]="pageTitleConfig?.pageTitleWrapperClass || 'col-12 col-md-8 align-items-start'">
      <!-- Search Bar -->
      <input *ngIf="pageTitleConfig?.hasOwnProperty('input') && pageTitleConfig?.input?.display"
        [(ngModel)]="lookupWord" (ngModelChange)="searchStringUpdate.next($event)" class="form-control"
        placeholder="{{pageTitleConfig?.input?.placeholder}}" />
      <!-- Page Title Back button -->
      <p class="mb-1 back-btn-caption font-size-14"
        *ngIf="pageTitleConfig && pageTitleConfig?.hasOwnProperty('backBtn')">
        <a href="javascript:void(0)" [routerLink]="pageTitleConfig.backBtn?.stateName" [id]="pageTitleConfig.backBtn?.id ? pageTitleConfig.backBtn?.id : 'app-header-pgtitle-lnk-back'">
          <img class="img-fluid back-arrow" src="assets/images/back-button.svg" id="app-header-pgtitle-ic-back" />
        </a>
        <span class="d-inline-block align-middle ms-1">{{pageTitleConfig.backBtn?.label}}</span>
      </p>
      <!-- Page Title -->
      <h2 class="text-capitalize mb-0 d-inline-block" *ngIf="pageTitleConfig && pageTitleConfig?.title?.label">
        <span class="d-inline-block fw-bold text-truncate font-size-24 width-adjust-90 me-1" id="app-header-pgtitle-title"
          placement="bottom" ngbTooltip="{{pageTitleConfig.title?.label}}" [disableTooltip]="pageTitleConfig.title?.disableTooltip || false">
          {{pageTitleConfig.title?.label}}
        </span>
        <i *ngIf="pageTitleConfig.hasOwnProperty('icon') && pageTitleConfig.icon?.loader === false" (click)="btnClickCallback({type: 'icon', key: pageTitleConfig.icon?.label})" id="app-header-pgtitle-icon-{{pageTitleConfig.icon?.id}}" ngbTooltip="{{pageTitleConfig.icon?.tooltipValue}}" [disableTooltip]="!pageTitleConfig.icon?.showTooltip" placement="bottom" class="align-top cursor-pointer mh-material-icons font-size-30" [ngClass]="pageTitleConfig.icon?.cssClass">
          <span class="visually-hidden">{{pageTitleConfig.icon?.label}}</span>
          {{pageTitleConfig.icon?.name}} 
        </i>
      <i *ngIf="pageTitleConfig.secondaryIcon?.value"
         tooltipClass="font-size-12" ngbTooltip="{{pageTitleConfig.secondaryIcon?.tooltipValue}}"
        [disableTooltip]="!pageTitleConfig.secondaryIcon?.showTooltip" placement="right">
        <img class="managed-lock-icon mb-3" [src]="pageTitleConfig.secondaryIcon?.imageSrc" alt="Managed Campaign">
      </i>
       
        <span *ngIf="pageTitleConfig.hasOwnProperty('icon')" [ngClass]="pageTitleConfig.icon?.loader ? 'd-inline-block' : 'd-none'" class="spinner-border spinner-border-sm save-loader border-3 align-top" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
      </h2>
    </div>
    <!-- Row: 1, Column: 2 => Action Bar Section -->
    <div [ngClass]="pageTitleConfig?.pageTitleActionWrapperClass || 'col-12 col-md-4 align-items-end'"
      *ngIf="pageTitleConfig?.hasOwnProperty('options')">
      <ul class="list-inline action-bar mb-0 mt-1 font-size-14 text-end">
        <li class="list-inline-item" [ngClass]="option.display ? 'd-block font-size-12 fw-light pt-1 me-2': 'fw-bold'" *ngFor="let option of pageTitleConfig?.options; let i = index;">
          <!--Template when display is set to false-->
          <ng-template [ngIf]="!option.display" [ngIfElse]="displayTrue">
            <!-- Page Title -->
            <span class="font-size-16" id="{{option.id}}" (click)="btnClickCallback({type: option.type, key: option?.value})"
              *ngIf="option?.label && option.type === 'text' && !option.display">{{option?.label}}:
              <span *ngIf="option?.label && option.type === 'text' && !option.display"
                [ngClass]="i === 0 ? 'fw-bold' : ''">{{option?.value}}
              </span>
            </span>
            <span *ngIf="option.type === 'link'" (click)="btnClickCallback({ type: option.type, key: option?.value })">
              <a class="link-primary font-size-16" id="pageTitleConfig?.id-{{i}}" href="javascript:void(0);">{{option?.label}}</a>
            </span>
            <ng-template [ngIf]="option.type === 'icon' && option.permissionScope">
              <span *ngxPermissionsOnly="option.permissionScope">
                <i class="mh-material-icons" ngClass="{{option.iconDetails?.cssClass}}" [attr.id]="option.iconDetails?.id" *ngIf="option?.iconDetails?.show" (click)="btnClickCallback({ type: option.type, key: option?.iconDetails?.label })">
                  <span class="visually-hidden">{{option.iconDetails?.label}}</span>
                  {{option.iconDetails?.name}}
                </i>
              </span>
            </ng-template>
            <!-- Action button bar With out Permission Scope defined -->
            <ng-template [ngIf]="option.type === 'button' && !option.permissionScope">
              <button [attr.id]="option?.id"
                class="btn" [ngClass]="option?.cssClass ||'fw-bold btn-primary'"
                (click)="btnClickCallback({type: option.type, key: option?.value, id: option?.id})">
                <span><i *ngIf="option?.icon" [ngClass]="option.icon"></i>{{option?.value}}</span>
              </button>
            </ng-template>
            <!-- Action button bar With Permission Scope defined -->
            <ng-template [ngIf]="option.type === 'button' && option.permissionScope">
              <button *ngxPermissionsOnly="option.permissionScope" [attr.id]="option?.id"
                class="btn" [ngClass]="option?.cssClass ||'fw-bold btn-primary'" [disabled]="option?.isDisabled"
                (click)="btnClickCallback({type: option.type, key: option?.value, id: option?.id})">
                <span><i *ngIf="option?.icon" [ngClass]="option.icon?.button?.cssClass">{{option.icon?.button?.name}}</i>
                  <span class="visually-hidden">{{option?.value}}</span>
                  {{option?.value}}
                </span>
                <!-- Badge -->
                <ng-template [ngIf]="option.alertBadge && option.alertBadge.permissionScope && badgeMessageText">
                  <span [attr.id]="option?.alertBadge?.id"  *ngxPermissionsOnly="option.alertBadge.permissionScope" [ngClass]="option?.alertBadge?.cssClass" class="d-flex align-items-center justify-content-center position-absolute alert-badge translate-middle rounded-circle">
                    <span class="visually-hidden">{{badgeMessageText}}</span>
                    {{badgeMessageText}}
                  </span>
                </ng-template>
              </button>
            </ng-template>
          </ng-template>
          <!--Template when display is set to true-->
          <ng-template #displayTrue>
            <span *ngIf="option.type !== 'date'">
              <b class="ps-1">{{option?.label}}:</b>
              <span class="ps-1">{{option?.value}}</span>
            </span>
            <span *ngIf="option.type === 'date'">
              <b class="ps-1">{{option?.label}}:</b>
              <span class="ps-1">
                {{option?.value | date : "dd MMM yyyy"}}
                <span class="d-inline-block p-1 align-middle">
                  <span class="vr opacity-75"></span>
                </span>
                {{option?.value | date : "hh:mm a"}} {{option?.valueSuffix}}
              </span>
            </span>
          </ng-template>
        </li>
        <li class="list-inline-item fw-bold font-size-16" *ngIf="pageTitleConfig?.hasOwnProperty('dropdown')">
          <div ngbDropdown class="d-inline-block">
            <a class="link-primary" id="{{pageTitleConfig?.dropdown?.id}}" ngbDropdownToggle>Share</a>
            <div ngbDropdownMenu [attr.aria-labelledby]="pageTitleConfig?.dropdown?.id" class="cs-dd-model">
              <a ngbDropdownItem *ngFor="let option of pageTitleConfig?.dropdown?.value"
                (click)="selectItem(option?.value)">{{option?.value}}</a>
            </div>
          </div>
        </li>
        <li class="list-inline-item fw-bold" *ngIf="pageTitleConfig?.canDelete"
          (click)="btnClickCallback({type: 'link', key: pageTitleConfig?.title?.label})">
          <a class="link-primary" id="app-header-pgtitle-option-lnk-label-delete" href="javascript:void(0);">
            <i class="bi bi-trash"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- Row: 2, Column: 1 => Sub Title Bar Section -->
    <div *ngIf="pageTitleConfig?.subTitle?.label" [ngClass]="pageTitleConfig?.pageTitleActionWrapperClass ||'col-12 ps-md-0'">
      <p class="mb-0 d-inline-block" *ngIf="pageTitleConfig?.hasOwnProperty('subTitle')">
        <span class="d-inline-block text-truncate font-size-14" id="app-header-pgtitle-sub-title" placement="bottom"
          ngbTooltip="{{pageTitleConfig?.subTitle?.label}}" [disableTooltip]="pageTitleConfig?.subTitle?.disableTooltip || false">
          {{pageTitleConfig?.subTitle?.label}}
        </span>
      </p>
    </div>
  </div>
</div>
