<div class="tags-panel" [attr.id]="idPrefix ? idPrefix+'-tags-panel' : 'tags-panel'">
  <div class="show-more-tag-panel">
    <cdk-virtual-scroll-viewport [itemSize]="10" class="tags p-2" [attr.id]="idPrefix ? idPrefix+'-tags' : 'tags'">
      <div *cdkVirtualFor="let tag of itemConfig | orderBy: 'description'"
        class="tag m-1 d-inline-flex font-size-14 text-center align-items-center"
        [ngbTooltip]="tipContent" placement="top" container="body">
        <span class="d-inline-block">
          <span class="fw-bold" *ngIf="tag.prefix">{{tag.prefix}}:</span> {{tag.description}}
        </span>
        <a href="javascript:void(0)" aria-label='Close Button' (click)="deselectAttribute(tag)">
          <i class="mh-material-icons close-select">close <span class="visually-hidden"></span></i>
        </a>
        <ng-template #tipContent>
          <span *ngIf="tag.description && (tag.code === tag.description)">{{tag.code}}</span>
          <span *ngIf="!(tag.description && (tag.code === tag.description))">
            <span class="fw-bold"
              *ngIf="tag.prefix && tag.prefix.length>0 && tag.code && tag.code.length>0">{{(tag.code).substring(tag.prefix.length+1)}}:
            </span>
            <span class="fw-bold" *ngIf="!tag.prefix">{{tag.code}}:</span> {{tag.description}}
          </span>
        </ng-template>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>