import crawl from 'tree-crawl';

export class TreeNode {
  key: string;
  id?: string;
  children: TreeNode[];
  checked?: boolean = false;
  parent?: string | null;
  isFullySelected?: boolean = false;
  limitTo?: number = 10;
  open?: boolean = false;
  description?: string | null = null;
  key_as_string?: string | null = null;
  showTooltip?: boolean;
  tooltipInfo?: string;
  showInfoPopup?: boolean;
  infoPopupLink?: string;
  
  constructor({ key, children }: { key: string; children: TreeNode[] | [] }) {
    this.key = key;
    this.children = children || [];
    this.id = '';
    this.parent = null;
    this.checked = false;
    this.open = false;
  }
}
  
export class Tree {
  
  root: TreeNode;
  selectedUniqueNodes!: Map<string, any>;
  
  constructor() {
    this.root = new TreeNode({ key: 'root', children: [] });
    this.selectedUniqueNodes = new Map<string, any>();
  }

  *traverse(node = this.root): Iterable<TreeNode> {
    if (!node) return;
    yield node;
    if (node.children?.length) {
      for (const child of node.children) {
        yield* this.traverse(child);
      }
    }
  }
  
  set(newNode: TreeNode, parentNodeName?: string | null): TreeNode[] {
    let insertedNodes: TreeNode[] = [];
    for (const node of this.traverse()) {
      if (node.key == parentNodeName) {
        node.children.push(newNode);
        insertedNodes.push(node);
      } else {
        insertedNodes = [];
      }
    }
    return insertedNodes;
  }
  
  get(nodeName: string): TreeNode[] {
    let nodeList: TreeNode[] = [];
    if (!this.root) return [];
    for (const node of this.traverse()) {
      if (node.key === nodeName) {
        nodeList.push(node);
      }
    }
    return nodeList;
  }

  filterFindFunction = (searchText: string) => (node: TreeNode) => {
    let query: string = node?.key_as_string + '' + node?.key;
    if (node?.parent) query += '' + node.parent;
    return new RegExp(searchText, 'i').test(query);
  };
  
  search(searchTerm: string, isNodeOpen: boolean = false) {
    const filteredNodes = this.filterNodes(this.root.children, this.filterFindFunction(searchTerm));
    if (Array.isArray(filteredNodes)) {
      this.root.children = filteredNodes as TreeNode[];
      return crawl(this.root, (node: TreeNode)=> {
        node.open = isNodeOpen;
      },  { order: 'post' });
    } else {
      this.root.children = [];
    }
  }

  private isBranch(nodeData:TreeNode) {
    const children = nodeData && nodeData.children;
    return children && children.length >= 0;
  }

  private filterNode(node: TreeNode, predicate: any, parents:any = []):TreeNode | null {
    let res = null;

    const filteredChildren = this.isBranch(node) ? node.children.map((childNode) =>{
      return this.filterNode(childNode, predicate, [...parents, node]);
    }).filter(i => i !== null) : null;

    const hasChildrenMatched = filteredChildren && filteredChildren.length > 0;
    const isNodeItselfMatched = predicate(node, parents);

    if (isNodeItselfMatched || hasChildrenMatched) {
      const childrenData = filteredChildren ? { children : filteredChildren } : {};
      res = Object.assign({}, node, childrenData);
    }

    return res;
  }

  private filterNodes(nodes:TreeNode[], predicate:any, parents = []) : TreeNode[] {
    const parentLevelFilterResults:TreeNode[] = nodes.filter(predicate);
    return parentLevelFilterResults.length ? parentLevelFilterResults : nodes.map(node => this.filterNode(node, predicate, parents)).filter(i => i !== null) as TreeNode[];
  }

  selectAll(flag = true): void {
    crawl(this.root, (node, context) => {
      if (node.key) {
        if (!context?.parent) {
          node.isFullySelected = !node.children.some(item => !item.checked);
          node.checked = flag;
          context.break();
        } else {
          if (!node.children.length) {
            node.checked = flag;
            node.isFullySelected = node.checked;
            if (node.checked && node.isFullySelected) this.selectedUniqueNodes.set(node.key, { key: node.key, description: node.key_as_string, parent: node.parent });
            else this.selectedUniqueNodes.delete(node.key);
          } else {
            let totalCheckedCount = 0;
            let totalFullySelectedCount = 0;
            for (let i = 0; i < node.children.length; i++) {
              if (node.children[i].checked) totalCheckedCount++;
              if (node.children[i].isFullySelected) totalFullySelectedCount++;
            }
            node.checked = totalCheckedCount > 0;
            node.isFullySelected = totalFullySelectedCount === node.children.length;
          }
        }
      } else {
        if (!node.children?.length && !node.parent) {
          if (node.checked && node.isFullySelected) this.selectedUniqueNodes.set(node.key, { key: node.key, description: node.key_as_string, parent: node.parent });
          else this.selectedUniqueNodes.delete(node.key);
        }
        context.skip();
      }
    }, { order: 'post' });
  }

  toggle(selectedNodeKey: string, state: boolean = true) {
    let parentNode: string | null;
    const traverseOrder: crawl.Options<TreeNode> = { order: 'post' }; 
    crawl(this.root, (node: TreeNode, context: crawl.Context<TreeNode>) => {
      if (selectedNodeKey === node.key) {
        parentNode = context?.parent?.key || null;
        if (!node?.children?.length) {
          node.checked = state;
          node.isFullySelected = state;
          if (node.checked && node.isFullySelected) this.selectedUniqueNodes.set(node.key, { key: node.key, description: node.key_as_string, parent: node.parent });
          else this.selectedUniqueNodes.delete(node.key);
        } else {
          crawl(node, (nestedNode: TreeNode) => {
            nestedNode.checked = state;
            nestedNode.isFullySelected = state;
            if (!nestedNode?.children?.length) {
              if (nestedNode.checked && nestedNode.isFullySelected) this.selectedUniqueNodes.set(nestedNode.key, { key: nestedNode.key, description: nestedNode.key_as_string, parent: nestedNode.parent });
              else this.selectedUniqueNodes.delete(nestedNode.key);
            }
          },  traverseOrder);
          node.checked = state;
          node.isFullySelected = state;
        }
      } else if (parentNode && node.key === parentNode) {
        parentNode = context.parent?.key || null;
        if (!node?.children?.length) {
          node.checked = state;
          node.isFullySelected = state;
        } else {
          let totalCheckedCount = 0;
          let totalFullySelectedCount = 0;
          for (let i = 0; i < node.children.length; i++) {
            if (node.children[i].checked) totalCheckedCount++;
            if (node.children[i].isFullySelected) totalFullySelectedCount++;
          }
          node.checked = totalCheckedCount > 0;
          node.isFullySelected = totalFullySelectedCount === node.children.length;
        }
      }
    }, traverseOrder );
  }

  expandOrCollapse(selectedNodeKey: string, state: boolean = true) {
    crawl(this.root, (node: TreeNode, context: crawl.Context<TreeNode>) => {
      if (selectedNodeKey === node.key) {
        node.open = state;
        context.break();
      } else context.skip();
    }, { order: 'post' });
  }

  loadMore(selectedNodeKey: string, limitTo = 10) {
    crawl(this.root, (node: TreeNode, context: crawl.Context<TreeNode>) => {
      if (selectedNodeKey === node.key) {
        node.limitTo = node?.limitTo as number + limitTo;
        context.break();
      } else context.skip();
    }, { order: 'post' });
  }
  
}