import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITag } from '../models/checkbox-tree/checkbox-tree.model';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  @Input() parent!: string;
  @Input() idPrefix!: string;
  @Input() itemConfig:ITag[] = [];
  @Output() itemRemoved = new EventEmitter<ITag>();

  deselectAttribute(tag:ITag) {
    if (this.parent) tag.consumer = this.parent;
    const index: number = this.itemConfig.findIndex(item => item.code === tag.code);
    if (index > -1) {
      this.itemConfig.splice(index, 1);
      tag.index = index;
      this.itemRemoved.emit(tag);
    }
  }
}
